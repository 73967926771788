.nemo_committee{
    .title{
        &:before {
            content: "";
            width: 20%;
            min-width: 300px;
            height: 1px;
            background: #000;
            display: block;
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
            margin-bottom:1em;
        }
    }
    .organization{
        padding:2em 5em;
        padding-bottom:0;
            @include tablet{
                padding: 2em 3em;
            }
            @include mobile{
                padding:0;
            }
        h2{
            width: 100%;
            text-align: center;
            margin-bottom:2em;
            margin-top:2em;
        }
        h5{
            font-family: $serif;
            width: 100%;
        }
        
    }
    .updater{
        font-size:14px;
    }
    h5{
        @include hasta(767px){
            font-size: 16px;
        }
        @include mobile{
            font-size: 14px;
        }
    }
    .member{
        width:100%;
        display:flex;
        align-items: center;
        margin-bottom:2em;
        // justify-content: center;
        @include hasta (767.5px){
            flex-direction: column;
        }
        .img{
            // background:red;
            border-radius:50%;
            overflow: hidden;
            @include mobile{
                margin-bottom:1em;
            }
        }
        .datas{
            padding:1em;
            padding-left:2.5em;
            @include mobile {
                padding:0;
            }
            li{
                font-family: $serif;
                span{
                    font-family: $sin-serif;
                }
            }
        }
    }
    .mco_plan{
        padding-bottom:3em;
        h2{
            width:100%;
            text-align: center;
            margin-top:1.5em;
            margin-bottom: 2em;
        }
        .download{
            width:100%;
            // display: block;
            span{
                font-size: 30px;
                margin-right: 0.3em;
                &.eye-icon{
                    font-size: 24px;
                }
                &:after{
                    @include btn-colors;
                }
            }
                @include reset-a($txt-col);
                font-family: $serif;
                &:hover{
                    color:$ieIcons;
                }
        }
    }
    .map-module{
        @include hasta (1200px){
            display:none;
        }
        #animation_container,canvas,.canvas-container{
            // width: 100% !important;;
            max-width: 100%!important;
            position: relative;
            margin:0 auto;
        }
    }

}
.table-legend{
    @include hasta(400px){
        display:none;
    }
}
.table{
    margin-bottom:2em;
    margin-top: 1em;
    max-width:100%;
    overflow: hidden;
    tr{
        max-width:100%;

    }
    @include hasta (600px){
        th,td{
            font-size: 12px;
            width:33%;
        }
    }
    @include hasta(400px){
        display:none;
    }
    .nemo-head{
        color:$fondo;
        background:$ieIcons-hover;
        // @include all-colors;
    }
}


.filter-control{
    @include reset-a($txt-col);
    margin-bottom:2em;
    margin-top:2em;
    position: relative;
    text-align: left;
    display: block;
    h2{
        
    }
    &:after{
        content:"";
        @include btn-colors;
        width:100%;
        height: 2px;
        display: block;
        position: absolute;
    }
    span{
        text-align: left;
        position:absolute;
        top:0px;
        left: 150px;
        display:block;
        // transition: $transition;
    }
    &.open{
        span{
            transform:rotate(180deg)
        }
    }
}
.filters{
    display:none;
    text-align: left;
    padding-bottom:2em;
    padding-top:2em;
    h2{
        text-align: center;
        margin-top: 2.5em;
    }
}

.sdac{
    .img-cont{
        width:100%;
        display: block;
    }
    img{
        max-width: 100%;
    }
    h2,h3{
        width: 100%;
    }
    p{
        // width: 100%;
    }
}