header{
    background: $fondo;
    position: absolute;
    // position: fixed;
    top:0;
    z-index: 999;
    transition: $transition;
    @include desktopS{
        @include shadow1;
    }
    .logo-container{
        padding-top:2em;
        padding-bottom: 2em;
        text-align: center;
        justify-content: center;
        img{
            width:150px;
            transition: $transition;
        }
        
        // background: $fondo;
        @include tablet{
            padding-top:1em;
        padding-bottom: 1em;
            img{
                width:100px;
            }
        }   
        @include hasta(575px){
            justify-content: flex-start;
            padding-left:2em;
            padding-bottom: 0;
        }
    }
    .navigation{
        // background:$fondo;
        position: relative;
        z-index: 2;;
        // border-bottom: 1px solid red;
        @include shadow1;
            @include desktopS{
                min-height: 10px;
            }

        .lvl1{
            width:100%;
            display: flex;
            justify-content: space-around;
            // padding-bottom: 1em;
            @include desktopS{
                // display:block;
                @include shadow1;
                display: none;
                position: absolute;
                left:0;
                padding:1em;
                width: 100%;
                background:#fff;
            }
            @include hasta(575px){
                padding:0 1em 0.5em 1em;
                padding-top: 1em;
            }
            // position: relative;
            // &>li{
            //     &:hover{
            //         .lvl2{
            //             opacity: 1;
            //             pointer-events: inherit;
            //         }
            //     }
            // }

        }//lvl1
        
    }//navigator
            a{
                @include reset-a($txt-col);
                transition:$transition;
                &:after{
                    transition:$transition;
                    margin:auto;
                    content:"";
                    margin-top: 0.6em;
                    width:0%;
                    height:2px;
                    display:block;
                    background:$txt-col;
                }
                &.opener{
                    color:$links-col;
                    &:after{
                        width:75%;
                        background: $links-col;
                        @include desktopS{
                            width:0;
                        }
                    }
                }
                &.active{
                    color:$txt-col;
                    &:after{
                        width:75%;
                        background: $txt-col;
                        @include desktopS{
                            width:0;
                            color:$links-col;
                        }
                    }
                            @include desktopS{
                                color:$links-col;
                            }
                }
                &.hover{
                    color:$links-col;
                    &:after{
                        content:"";
                        margin:auto;
                        margin-top: 0.6em;
                        width:75%;
                        height:2px;
                        background:$links-col;
                        @include desktopS{
                            width:0;
                        }
                    }
                }
            }//a --> todos los que hay dentro de header



            .lvls{
                background:$gris;
                position: absolute;
                width: 100%;
                z-index: 1;
                @include shadow2;
                @include desktopS{
                    display:none;
                }
                .lvl2{
                    padding-top:1em;
                    // display: none;
                    width: 100%;
                    // opacity:0;
                    // pointer-events: none;
                    // border-top:10px solid transparent;
                    width: 100%;
                    // position: absolute;
                    // z-index: 1;
                    left:0;
                    // top:210px;
                    display: none;
                    .lvl2-cont{
                        padding-top:1em;
                        left:0;
                        display: flex;
                        // width: 100%;
                        justify-content: space-between;
                    }
                    li{
                        // width: 100%;
                    }
                    a{
                        // position: relative;
                        // width: 100%;
                    }
                    a.ind{
                        text-transform: uppercase;
                        &.hover{
                            &:after{
                                display:none;
                            }
                        }
                    }
                }//lvl2
            }//lvls
            &.on-move{
                top:-150px;
                // position: fixed;
                
                .logo-container{
                    padding-top:1em;
                    padding-bottom: 0em;
                    img{
                        width:75px;
                        @include desktopS{
                            width:100px;
                        }
                    }
                } 
                &.showing {
                    position: fixed;
                    top: 0;
                }
            }


}//header


.burger-cont{
    width: 25px;
    display:none;
    @include tablet{
    }
    @include desktopS{
        right: 25px;
        top:10px;
        position: absolute;
        // right:0px;
        display:block;
    }
    #burger {
        display: none;
      }
      
      label {
        position: relative;
        width: 100%;
        max-width:40px;
        height: 25px;
        display: inline-block;
        cursor: pointer;
        // margin: 1.7em;
        margin-top:1.6em;
        transition: all .5s;
        text-align: left;
      }
      
      div,
      div:before,
      div:after {
        background: #666;
        position: absolute;
        height: 2px;
        width: 100%;
        max-width:40px;
        border-radius: 4px;
        transition: all .5s;
      }
      
      label {
        div:first-child {
          top: 0px;
        }
        div:nth-child(2) {
          top: 10px;
          right:0;
        }
        div:nth-child(3) {
            width:80%;
          top: 20px;
          right:0;
        }
      }
    #burger + label {
        div:first-child {
          transition: top .15s .15s, transform .15s;
        }
        div:nth-child(2),
        div:nth-child(3) {
          transition: top .15s .15s, transform .15s;
         
        }
      }
      
      #burger:checked + label {
        div:first-child {
          top: 10px;
          transform: rotate(45deg);
          transition: top .15s, transform .15s .15s;
        }
        div:nth-child(2), 
        div:nth-child(3) {
          top: 10px;
          transform: rotate(-45deg);
          transition: top .15s, transform .15s .15s;
          width:100%;
          right: inherit;;
        }
        
      }
}//burger-cont