
.filter-module{
    font-family: $serif;
    .filter-control{
        @include reset-a($txt-col);
        margin-bottom:2em;
        position: relative;
        span{
            position:absolute;
            top:0px;
            left: 100px;
            display:block;
            // transition: $transition;
        }
        &.open{
            span{
                transform:rotate(180deg)
            }
        }
        &:after{
            display: none;
        }
    }
    .filters{
        border:1px solid $ieIcons;
        padding:2em;
        display:none;
    }
    .filter-list{
        li{
            margin-bottom:1em;
            padding-left:2.5em;
            cursor:pointer;
            position:relative;
            // font-size: 24px;
            &.hover{
                text-decoration:underline;
            }
            &:before{
                font-family: "nemo-icons";
                content:"\AC09";
                display: block;
                color:$ieIcons;
                position: absolute;
                left:0px;
                top:-8px;
                font-size:24px;
                transform: scale(0);
                transition:$transition;

            }
            &.active{
                &:before{
                    transform: scale(1)

                }
            }
            h2{ 
                margin-top:0;
                text-align: left;
            }
        }
    }
}


.consultations-module{
    padding-top:4em;
    padding-bottom:2em;
  h1{
      text-align: center;
  }
  .consultations-year{
      width: 100%;
      margin-top:2em;
      &:after{
          margin-top:0.5em;
          content:"";
          display:block;
          @include all-colors;
          width:100%;
          height: 4px;
      }
  }
  .consultation{
      display:flex;
      width:100%;
    //   margin-bottom:2em
      padding:2em;
      @include hasta(767.5px){
          padding:1em;
          margin-bottom:1em;
          display:flex;
          flex-direction:column;
          width: 100%;
          max-width: 100%;
          height: 100%;
        }
        .img-cont{
            img{
                max-width: 100%;
            }
          }
    }
    .icon-cont{
        width:100px;
        // text-align: left;
        text-align: center;
        margin-right: 1em;
        span{
            font-size: 60px;
        }
        @include hasta(767.5px){
            width:100%;
            margin-right: 0;
        }
  }
  
  .consultation-content{
    width:100%
  }
  .date{
    @include hasta(767.5px){
        text-align: center;
    }
  }
  .cta{
    //   margin:0 auto;
      width:300px;
      text-align: center;
      max-width: 100%;
      a{
          margin:0;
          margin-top:1.5em;
          text-align: left;
      }
  }
}