// @import url(../../src/css/libs/fa/font-awesome.min.css);
@import url('https://fonts.googleapis.com/css?family=Inknut+Antiqua:400,700|Lato:300,400,700&display=swap');
@font-face {
	font-family: 'nemo-icons';
	src: url('./fonts/nemo-icons/ANCtype-Icons.woff2') format('woff2'),
		url('./fonts/nemo-icons/ANCtype-Icons.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

body{
    font-family:  $sin-serif;
}
h1{
    font-weight: 300;
    font-size:$h1;
    line-height: 1em;
    margin-bottom:0.8em;
    @include hasta(1200px){
        font-size:$h1*0.9;
    }
    @include desktopS{
        font-size:$h1*0.6;
    }
    @include hasta(767.5px){
        font-size:$h1*0.4;
    }
}
h2{
    font-family: $serif;
    margin-bottom:1em;
    font-weight: 700;
    font-size: $h2;
    @include hasta(1200px){
        font-size:$h2*0.9;
    }
    @include desktopS{
        font-size:$h2*0.85;
    }
    @include hasta(767.5px){
        font-size:$h2*0.75;
    }
}
h3{
    @extend h2;
    font-family:$sin-serif; 
    margin-bottom: 0.5em;   
}
h5{
    @extend h2;
}
p,li{
    font-size:$txt;
    @include tablet{
        font-size:$txt * 0.9;
    }
    @include mobile{
        font-size:$txt * 0.8;
    }
}
ol li{
    font-family: $serif;
    font-size:20px;
    @include mobile{
        font-size:16px;

    }
    p{
        font-family: $sin-serif;
        
    }
}
a{
    @include reset-a($links-col);
    text-decoration: underline;
    &:hover{
        text-decoration: underline;
    }
    &.disabled{
        pointer-events: none;
        user-select: none;
        opacity:0.3;
        display: inline-block;
    }
}
.nemo-back{
    text-decoration: none;
    position: relative;
    &:before{
        content:"<";
        font-size:20px;
        display:block;
        position:absolute;
        left:-12px;
        top:-6px;
        transition:$transition;

    }
    &.hover{
        &:before{
            left:-20px;
        }
    }
}

.colored {
	text-transform: uppercase;
	// background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
    //background: linear-gradient(to right, $soft 0%, $medium 50%, $hard 100%);
    @include btn-colors;
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:before{
	// background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
    //background: linear-gradient(to right, $soft 0%, $medium 50%, $hard 100%);
    @include btn-colors;
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    }
    &.hover{
        -webkit-background-clip: none;
        // -webkit-text-fill-color: $hard;
        // color:$hard;
        background:none;
    }
    &.iex{
        &:before{
            background:$fondo;
            -webkit-background-clip: none;
        -webkit-text-fill-color: none;
        color:$ieIcons;
        }
    }
}
.nemo-btn{
    display:block;
    // padding:0.2em;
    margin:0 auto;
    margin-top:1.5em;
    width: 60%;
    min-width:200px;
    @include reset-a($txt-col);
    font-family: $serif;
    // @include btn-colors;
    // transition:$transition;
    span{
        // background:$fondo;
        display: block;
        // padding:0.8em;
        transition:$transition;
        font-weight: 700;
    }
    &.hover{
        // padding:0.5em;
        color:$links-col;
        span{
            // color:$fondo;
            // background:transparent;
            // padding:0.5em;
        }
    }
    &.updater{
        width:auto;
    }
}
.modal{
    .nemo-btn{
        border:1px solid $txt-col;
        padding:0.5em;
        span{
            transition:none;
        }
        &:hover{
            color:$links-col;
            border:1px solid $links-col;
        }
    }
}
.decor-all{
    &:after{
        content:"";
        @include all-colors;
        width:100%;
        height: 4px;
        display: block;
    }
}
.title{
    @extend h1;
    margin-top:0.8em;
    text-align:center;
    width:100%;
    @include hasta(767.5px){
        margin-top:1.5em;
        margin-bottom:1.5em;
    }
}
.download{
    width:100%;
    display: block;
    span{
        font-size: 30px;
        margin-right: 0.3em;
        &.eye-icon{
            font-size: 24px;
        }
        &:after{
            @include btn-colors;
        }
    }
        @include reset-a($txt-col);
        font-family: $serif;
        &:hover{
            color:$ieIcons;
        }
}
/*
  /$$$$$$  /$$   /$$ /$$$$$$$$  /$$$$$$  /$$   /$$  /$$$$$$ 
 /$$__  $$| $$  | $$| $$_____/ /$$__  $$| $$  /$$/ /$$__  $$
| $$  \__/| $$  | $$| $$      | $$  \__/| $$ /$$/ | $$  \__/
| $$      | $$$$$$$$| $$$$$   | $$      | $$$$$/  |  $$$$$$ 
| $$      | $$__  $$| $$__/   | $$      | $$  $$   \____  $$
| $$    $$| $$  | $$| $$      | $$    $$| $$\  $$  /$$  \ $$
|  $$$$$$/| $$  | $$| $$$$$$$$|  $$$$$$/| $$ \  $$|  $$$$$$/
 \______/ |__/  |__/|________/ \______/ |__/  \__/ \______/                                                         
                                                            
*/
 
    .check-container{
      display:flex;
      padding-top:1em;
      padding-bottom:1em;
      flex-wrap: wrap;
  }
  .check-container input[type="checkbox"],
  .check-container input[type="radio"] {
      // display: none;
      width:0;
      height:0;
      opacity: 0;
    }
    .check-container input[type="checkbox"] + label,
    .check-container input[type="radio"] + label{
        display: none;
  }
  .check-container  input[type="checkbox"] + label,
  .check-container  input[type="radio"] + label {
      color:$txt-col;
      font-family: Arial, sans-serif;
      font-size: 14px;
    }
    
    .check-container  input[type="checkbox"] + label span,
    .check-container  input[type="radio"] + label span {
      display: inline-block;
      width: 19px;
      height: 19px;
      margin: 0px 8px 0 0;
      vertical-align: middle;
      cursor: pointer;
      position: relative;
      right: 0;
      top: 5px;
      @include mobile{
          top:0;
      }
    }
    
    .check-container input[type="checkbox"] + label span,
    .check-container input[type="radio"] + label span {
      background-color: none;
      border:1px solid #ccc;
    }
    .check-container.has-error input[type="checkbox"] + label span,
    .check-container.has-error input[type="radio"] + label span{
      border:1px solid $ieIcons;
      
    }
    
    .check-container input[type="checkbox"]:checked + label span,
    .check-container input[type="radio"]:checked + label span {
    //   background-color: $ieIcons;
    @include btn-colors;
    }
    
    .check-container  input[type="checkbox"] + label span,
    .check-container  input[type="checkbox"]:checked + label span,
    .check-container  input[type="radio"] + label span,
    .check-container  input[type="radio"]:checked + label span {
    display: block;
    }
  
    .check-container  input[type="checkbox"] + label,
    .check-container input[type="checkbox"] + label,
    .check-container  input[type="radio"] + label,
    .check-container input[type="radio"] + label{
      display: block;
  }

  .follow-news{
      position:fixed;
      top: 40%;
      right:0;
      text-align: center;
     
      a{
          border:1px solid $txt-col;
          padding:1em;
          background:$fondo;
          span{
              transition:none;
          }
          &.hover{
            border:1px solid $links-col;
          }
      }
      @include mobile{
        top:inherit;
        bottom:0;
        right:0;
        width: 100%;
        a{
            width:100%;
        }
    }
    &--static{
        @extend .follow-news;
        position:relative;
        max-width:300px;
        margin: 0 auto;
    }
  }
  .big-image{
      position: relative;
      display:inline-block;
      text-decoration: none;
    //   display:flex;
      img{
          max-width:250px;
      }
     
      &:after{  
          opacity: 0.4;
            font-family: 'nemo-icons';
            // content:"\AC08";
            position: absolute;
            color:#fff;
            z-index: 99;
            left:0;
            right: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin:auto;
            top:0;
            bottom:0;
            font-size: 80px;
            text-decoration: none;
            transition:$transition; 
      }
      &.hover,&:hover{
        text-decoration: none;
        &:after{
          opacity: 1;
        }
    }
  }
  .modal {
      img{
          display:block;
          margin:0 auto;
      }
  }
  .pdf-file{
    display:flex;
    align-items: center;
    padding:1em;
    width:200px;
    margin:1em 0;
    font-weight: 700;
    // justify-content: flex-start;
    // width:auto;
      &.hover{
        //   color:$links-col;
        // text-decoration: underline;
      }
      span{
        //   display: inline-block;
        font-size: 25px;
        margin-right:0.5em;
        padding-bottom: 0.2em;
      }
  }
  /*
    /$$$$$$  /$$        /$$$$$$   /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$$  /$$     /$$
   /$$__  $$| $$       /$$__  $$ /$$__  $$ /$$__  $$ /$$__  $$| $$__  $$|  $$   /$$/
  | $$  \__/| $$      | $$  \ $$| $$  \__/| $$  \__/| $$  \ $$| $$  \ $$ \  $$ /$$/ 
  | $$ /$$$$| $$      | $$  | $$|  $$$$$$ |  $$$$$$ | $$$$$$$$| $$$$$$$/  \  $$$$/  
  | $$|_  $$| $$      | $$  | $$ \____  $$ \____  $$| $$__  $$| $$__  $$   \  $$/   
  | $$  \ $$| $$      | $$  | $$ /$$  \ $$ /$$  \ $$| $$  | $$| $$  \ $$    | $$    
  |  $$$$$$/| $$$$$$$$|  $$$$$$/|  $$$$$$/|  $$$$$$/| $$  | $$| $$  | $$    | $$    
   \______/ |________/ \______/  \______/  \______/ |__/  |__/|__/  |__/    |__/    
                                                                                    
                                                                                    
                                                                                    
  */
.glossary-module{
    
}
  