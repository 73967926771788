.nemos-module{
    padding-top:3em;
    padding-bottom:3em;
    a{
        display: block;
        width:25%;
        text-align: center;
        @include desktopS{
            width:25%;
        }
        @include hasta(767.5px){
            width:33%;
        }
        img{
            max-width:100%;
            transform:scale(0.9);
            transition:$transition;
        }
        &.hover{
            img{
                transform: scale(1)
            }
        }
    }
}