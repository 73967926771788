/*
_____  _    _____ 
 | |  \ \_/  | |  
 |_|  /_/ \  |_|  

 */
// $bigmsn:60px;
// $txt1:28px;
// $txt2:24px;
// $txt3:20px;
// $txt4:18px;
// $txt5:14px;
$txt:18px;
$h2:20px;
$h1:58px;
/*
Tipos
*/
 $sin-serif:'Lato', sans-serif;
 $serif:'Inknut Antiqua', serif;
 

/*
varios
*/
$transition:all .3s ease-out;
/*
 __    ___   _     ___   ___   ____  __  
/ /`  / / \ | |   / / \ | |_) | |_  ( (` 
\_\_, \_\_/ |_|__ \_\_/ |_| \ |_|__ _)_) 

*/
$txt-col:#000;
$links-col:#ee6538;
$gris:#ebebeb;
$fondo:#fff;
$ieIcons:#c23154;
$ieIcons-hover:#79265d;
// $valor:#003b71;

// $body-color:#fff;
// $bp-green:#006737;
// $alert:#d41b1b;
// $hover:#a7cc2a;
// $bp-soft:#009900;




/*
 ___   ____  __   ___  
| |_) | |_  ( (` | |_) 
|_| \ |_|__ _)_) |_|   
*/
$desktop:1920px;
$desktop:1280px;
$desktopS:992px;
$tablet: 768px;
$mobile:425px;
$mobileS:330px;