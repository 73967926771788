.contact-module{
    padding-top:3em;
    padding-bottom:3em;
    h2{
        margin-bottom:2em;
    }
    .row{
        align-items: center;
    }
    .img-container{
        margin-bottom:2em;
        img{
            max-width: 100%;
        }
    }
    .text-container{
        p.legend{
            @extend h2;
            margin-bottom: 0;   
        }
    }
} 

.glossary-module{
    padding-top:3em;
    padding-bottom:3em;
    ul{
        width:100%;
        @include hasta(767px){
            column-count: 1;
        }
        li{
            // padding:1em;
            @include hasta(767px){
                padding:0.5em 1em;
            }
        }
    }
    .legend{
        @extend h2;
        margin-bottom:0em
    }
    

    .index{
        width:100%;
        text-transform: uppercase;
        font-family: $serif;
        font-weight: 700;
        margin-bottom:1em;

        &:after{
            margin-top:0.5em;
            content:"";
            display:block;
            @include all-colors;
            width:100%;
            height: 4px;
        }
    }
    .filter-control{  
        margin-top:0;
        margin-bottom:0;
        position: relative;
        &:after{
            display:none;
        }  
        .term{
            font-family:$serif;
            font-weight:700; 
            font-size:$h2;
            @include preTablet{
                font-size:$h2*0.8;
            }
            @include mobile{
                font-size:$h2*0.6;

            }
        }
                span{
                    left:inherit;
                    position: relative;
                    display: inline-block;
                    margin-left:0.5em;
                // position: absolute;
                // right:0;
                }
        
    }
            .filters.description{
                padding-top:0;
            }
}
