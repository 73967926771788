
@import 'variables';
@import 'extends';
@import 'mixins';
// @import '../css/libs/bootstrap-all/bootstrap.min.css';
@import '../css/libs/bootstrap-all/scss/bootstrap-reboot';
@import '../css/libs/bootstrap-all/scss/bootstrap-grid';
@import '../css/libs/bootstrap-all/scss/bootstrap';
@import 'modules/common/icons'; 
// @import '../css/libs/fa/font-awesome.min.css';
@import 'modules/common/common';
@import 'modules/common/header';
@import 'modules/common/footer';
@import 'modules/common/banners'; 
@import 'modules/common/contact'; 
@import '../css/libs/slick/slick';
@import 'modules/home/updates-module'; 
@import 'modules/common/nemos-module'; 
@import 'modules/consultations/consultations-module';
@import 'modules/consultations/consultation';
@import 'modules/nemo_committee/nemo_committee_info';
@import '../js/libs/lightbox/css/lightbox.min.css'
// @import 'libs/slick/slick-theme.css';
// @import 'modules/common/header';
// @import 'modules/common/footer';
// @import 'modules/common/breadcrumbs';
// @import 'modules/home/home-banner';
// @import 'modules/home/video-module';
// @import 'modules/section/section-banner';
// @import 'modules/section/top-text';
// @import 'modules/section/image-text';
// @import 'modules/section/cases';
// @import 'modules/section/relational';
// @import 'modules/section/bottom-banner';
// @import 'modules/management-screen';
// @import 'modules/section/more-services';
// @import 'modules/blog/grid';
// @import 'modules/blog/detail';
// @import 'modules/contact/form';
// @import 'modules/location/location';
// @import 'modules/static_styles';