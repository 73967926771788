.banner-module{
    padding-top:3em;
    text-align: center;
    @include desktopS{
        padding-top:2em;
    }
    h1{

    }
    .slider-container{
        @include tablet{
            padding:0;
        }
    }
    .images-container{
        margin-bottom:2em;
        img{
            max-width: 100%;
        }
    }
    h2{

    }
}//banner-module