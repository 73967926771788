.updates-module{
    padding-top:2em;
    &:after{
        content:"";
        width: 20%;
        min-width: 300px;
        height:1px;
        background:$txt-col;
        display: block;
        position: relative;
        left: 0;
        right: 0;
        margin:auto;
    }
    &:before{
        content:"";
        width: 20%;
        min-width: 300px;
        height:1px;
        background:$txt-col;
        display: block;
        position: relative;
        left: 0;
        right: 0;
        margin:auto;
    }
    h2{
        @extend h1;
        margin-top:0.8em;
        text-align:center;
        width:100%;
        @include hasta(767.5px){
            margin-top:1.5em;
            margin-bottom:1.5em;
        }
    }
    .updates{
        margin-bottom:6em;
        @include hasta(767.5px){
            margin-bottom:4em;
        }
    }
    .update{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include hasta(767.5px){
            margin-bottom:4em;
            &:nth-child(3){
                margin-bottom: 0;
            }
        }
    }
    .icon-cont{
        span{
            font-size:50px;
        }
        margin-bottom:1em;
    }
    .descr{
        font-family: $serif;
        font-weight: 700;
        @include desktopS{
            p{
                font-size:$txt*0.85;
            }
        }
        p{
            margin-bottom:0.2em;
        }
    }
}

.updaterMod{
    padding-bottom:50px;
    &:after{
        content:"";
        width: 20%;
        min-width: 300px;
        // margin-top:30px;
        display:block;
        height:1px;
        background:$txt-col;
        display: block;
        position: relative;
        top:50px;
        left: 0;
        right: 0;
        margin:auto;
    }
}
.news .updaterMod{
    &:after{
        display:none;
    }
}