.consultation-banner{
    .consult-logos-cont{
        padding-bottom:2.5em;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .title{
        margin-bottom: 2em;
        @include hasta(575px){
            padding:0 15px 0 15px; 
        }
    }
    .nemo-back-cont{
        text-align: left;
        padding:0 3em;
    }
    
    .decor-all{
        &:after{
            margin-top: 4em;
        }
    }
}//consultation-banner

.consult-info{
    padding:0 3em;
    text-align: left;
    margin-top:1.5em;
    @include hasta(767.5px){
        padding:0;
        padding-right: 15px;
        padding-left:15px;

    }
    h2{
        text-align: center;
    }
}//consult-info





 .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    max-width: 80%;
    font-size: 1.25rem;
    /* 20px */
    // font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
    i{
        font-size:40px;
        @include mobile{
            font-size: 30px;
        }
    }
}

.inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

.inputfile + label i {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}

.inputfile-3 + label {
    // color: #d3394c;
}

.inputfile-3:focus + label,
.inputfile-3.has-focus + label,
.inputfile-3 + label:hover {
    // color: #722040;
}











.consultation-info{
    h3{
        @extend h1;
        text-align: center;
        width: 100%;
        margin-top:1em;
    }
    .label{
        font-family:$serif;
        font-weight: bold;
        margin-bottom:0;
        margin-top:1em;
    }
    .datas,
    .dates-cont{
        display:flex;
        flex-wrap: wrap;
        a{
            display: block;
            margin-bottom:0.5em;
        }
    }
    .dates-info{
        margin-bottom:4em;
    }
    .contact-info{

    }
    .dates{

    }
    .on-next{
        margin-bottom:4em;
        .field{
            width:100%;
            text-align: center;
        }
    }
    .questions{
        p{
            &>b{
                font-family: $serif;
                padding-right: 0.5em;
            }
        }
        li{
            font-family: $serif;
            font-size:20px;
            p{
                font-family: $sin-serif;
                
            }
        }
    }
    .form-container{
        padding-bottom::4em;
        form{
            padding-top:2em;
            width:50%;
            min-width:300px;
            padding-bottom:2em;
            // background:red;
            margin:0 auto;
             @include hasta(767.5px){
                width:100%;
                max-width:100%;
                padding-top:5em;
             }
            h2{ 
                position: relative;
                &:before{
                    font-family: 'nemo-icons';
                    content:"\AC07";
                    position: absolute;
                    left:-60px;
                    top:-20px;
                    font-size:50px;
                    @include btn-colors;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 300;
                    @include hasta(767.5px){
                        top:-70px;
                        // margin:0 auto;
                        left:0;
                        right: 0;
                        text-align: center;
                    }
                }
                &.iex{
                    &:before{
                        background:none;
                        color:$ieIcons;
                    }
                }
            }
            .input-field{
                width:100%;
                input{
                    width: 100%;
                    padding:0.5em;
                    margin-bottom:1em;
                    border:1px solid $txt-col;
                    &#file{
                        width:0;
                        border:none;
                        outline: none;
                        &:hover,&:visited,&:active,&:link{
                            outline:none;
                            border:none;
                        }
                        &+label{
                            border:none;
                            outline: none;
                            .nemo-icons:before{
                                transform:scale(0.8);
                                transition:$transition;
                            }
                           &:visited,&:active,&:link{
                                outline:none;
                                border:none;
                                .nemo-icons:before{
                                    transform:scale(0.8);
                                }
                            }
                            &:hover{
                                transform:scale(1);

                            }
                        }
                    }
                }
                textarea{
                    width: 100%;
                    height: 200px;
                    padding:0.5em;
                    border:1px solid $txt-col;
                }
            }
            .upload-field{
                label{
                    display: block;
                    &>span{
                        position: relative;
                        top:10px;
                    }
                }
                .file{
                    font-family: $serif;
                    @include mobile (){
                        font-size:$txt*0.8;
                    }
                }
            }
            .legal-cont{
                
            }
            .cta{
                text-align: left;
                a{
                    margin:0;
                    text-align: center;
                    border:1px solid $txt-col;
                    padding:1em;
                    span{
                        transition:none;
                    }
                    &.hover{
                        border:1px solid $links-col;
                    }
                }
            }
        }
    }
    .help-block{
        display: block;
        width:100%;
        color:$ieIcons;
        padding-bottom:1em;
    }

}

