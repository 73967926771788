@mixin reset-a($color-a){
		text-decoration:none;
		color:$color-a;
		&:hover,&:focus,&:visited{
			text-decoration:none;
			color:$color-a;
			outline:none;
		}
		&.hover{
			text-decoration:none;
			color:$color-a;
			outline:none;
		}
	}
	@mixin btn-colors{
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e72929+0,c23154+50,79265d+100 */
background: #e72929; /* Old browsers */
background: -moz-linear-gradient(left,  #79265d 0%, #c23154 50%,  #e72929 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  #79265d 0%,#c23154 50%, #e72929 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  #79265d 0%,#c23154 50%, #e72929 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e72929', endColorstr='#79265d',GradientType=1 ); /* IE6-9 */

	}
@mixin all-colors{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#86c540+0,f8e204+12,f6a114+25,ee6538+37,e72929+50,c23154+62,79265d+75,482f73+87,1b7164+100 */
background: #86c540; /* Old browsers */
background: -moz-linear-gradient(left,  #86c540 0%, #f8e204 12%, #f6a114 25%, #ee6538 37%, #e72929 50%, #c23154 62%, #79265d 75%, #482f73 87%, #1b7164 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  #86c540 0%,#f8e204 12%,#f6a114 25%,#ee6538 37%,#e72929 50%,#c23154 62%,#79265d 75%,#482f73 87%,#1b7164 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  #86c540 0%,#f8e204 12%,#f6a114 25%,#ee6538 37%,#e72929 50%,#c23154 62%,#79265d 75%,#482f73 87%,#1b7164 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#86c540', endColorstr='#1b7164',GradientType=1 ); /* IE6-9 */

}
@mixin sdac-colors{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#79265d+0,482f73+50,1b7164+100 */
background: #79265d; /* Old browsers */
background: -moz-linear-gradient(left,  #79265d 0%, #482f73 50%, #1b7164 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  #79265d 0%,#482f73 50%,#1b7164 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  #79265d 0%,#482f73 50%,#1b7164 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#79265d', endColorstr='#1b7164',GradientType=1 ); /* IE6-9 */

}
@mixin sdic-colors{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#79265d+0,482f73+50,1b7164+100 */
background: #79265d; /* Old browsers */
background: -moz-linear-gradient(left,  #79265d 0%, #482f73 50%, #1b7164 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  #79265d 0%,#482f73 50%,#1b7164 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  #79265d 0%,#482f73 50%,#1b7164 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#79265d', endColorstr='#1b7164',GradientType=1 ); /* IE6-9 */

}
@mixin shadow1{
	-webkit-box-shadow: 0px 5px 8px -5px rgba(69,69,69,0.3);
-moz-box-shadow: 0px 5px 8px -5px rgba(69,69,69,0.3);
box-shadow: 0px 5px 8px -5px rgba(69,69,69,0.3);
}
@mixin shadow2{
	-webkit-box-shadow: 0px 4px 5px -1px rgba(69,69,69,0.2);
	-moz-box-shadow: 0px 4px 5px -1px rgba(69,69,69,0.2);
	box-shadow: 0px 4px 5px -1px rgba(69,69,69,0.2);
}
@mixin hasta($hasta) {
	@media (max-width: #{$hasta}) {
		@content;
	}
	}
	@mixin desde($desde) {
		@media (min-width: #{$desde}) {
			@content;
		}
		}
	@mixin desktopB {
		@media (min-width: #{$desktop + 1}) {
		  @content;
		}
	  }
	@mixin desktop {
		@media (max-width: #{$desktop}) {
		  @content;
		}
	  }
	  @mixin desktopS {
		@media (max-width: #{$desktopS}) {
		  @content;
		}
	  }
	@mixin tablet {
		@media (max-width: #{$tablet}) {
		  @content;
		}
		}
		@mixin preTablet {
			@media (max-width: #{$tablet - 0.5}) {
				@content;
			}
			}
	@mixin mobile {
		@media (max-width: #{$mobile}) {
		  @content;
		}
	  }
	@mixin mobileS {
		@media (max-width: #{$mobileS}) {
		  @content;
		}
	  }

//@include desktop {}





// @mixin placeH($col){
// 	  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
// 		color: $col;
// 	  }
// 	  &::-moz-placeholder { /* Firefox 19+ */
// 		color: $col;
// 	  }
// 	  &:-ms-input-placeholder { /* IE 10+ */
// 		color: $col;
// 	  }
// 	  &:-moz-placeholder { /* Firefox 18- */
// 		color: $col;
// 	  }
// }
//@include placeholder (#fff);






/////////////////////////////////////////////////////////////////////////////////////
	// @mixin mediaQ($media) {
	// 	@if $media == big-desktop {
	// 	  @media only screen and (min-width: $desktop) { @content; }
	// 	}
	// 	@else if $media == desktop {
	// 	  @media only screen and (max-width: $desktop - 1) { @content; }
	// 	}
	// 	@else if $media == tablet {
	// 	  @media only screen and (max-width: $tablet) { @content; }
	// 	}
	// 	@else if $media == mobile {
	// 		@media only screen and (max-width: $tablet - 1) { @content; }
	// 	  }
	// 	  @else if $media == mobileMini {
	// 		@media only screen and (max-width: $mobile-mini) { @content; }
	// 	  }

	// }

	// @include mediaQ(handhelds) { width: 100% ;}

////////////////////////////////////////////////////////////////////////////////////////////









// @mixin ma-shadow (){
// 	-webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
//     -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
//     box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
// }

// @mixin var-td($fondo){
// 				width:17%;
//                 background: $fondo;
//                 padding:0.7em;
// 				border-right: 6px solid #fff;
// 				&:nth-child(5){
//                     border:none;
//                 }
// }

// @mixin box-sombras($color){
// 					-webkit-box-shadow: 8px 8px 0px 0px $color;
// -moz-box-shadow: 8px 8px 0px 0px $color;
// box-shadow: 8px 8px 0px 0px $color;
// }
// @mixin reset-a($color-a){
// 	text-decoration:none;
// 	color:$color-a;
// 	&:hover,&:focus,&:visited{
// 		text-decoration:none;
// 		color:$color-a;
// 		outline:none;
// 	}
// }

// @mixin img-sombra($color){
// 	-webkit-box-shadow: -8px 8px 0px 0px$color;
// -moz-box-shadow: -8px 8px 0px 0px $color;
// box-shadow: -8px 8px 0px 0px $color;
// }

// @mixin transition{
// 	transition:all 0.3s ease-out;
// }
// @mixin transition-t($time){
// 	transition:all $time ease-out;
// }
// .cajamix{
// 	@include colores(#000,green);
// 	color:#fff;
// }
