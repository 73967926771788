footer{
    background:#eee;
    padding-top: 2em;
    padding-bottom:2em;
    @include hasta (575px){
        font-size:$txt*0.65;
    }
    a{
        @include reset-a($txt-col);
        font-weight: 700;
        &.hover{
            color:$links-col;
        }
    }
.footer-top{
}
.decoration{
width:100%;
display: block;
height: 4px;
@include all-colors;
margin:2em 0;
}
.footer-bottom{
}
ul{
    display:flex;
    align-items: center;
    justify-content:center;
    margin:0;
    @include mobile{
        flex-direction: column;
        li{
            margin:0.2em 0;
        }
    }
    .sep{
        width:2px;
        margin:0 1em;
        background: $txt-col;
        height: 20px;
        display: block;
        @include mobile{
            display:none;
        }
    }
}

}//footer