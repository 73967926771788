.nemo-icons{
    font-family: 'nemo-icons';
    font-style: normal;
    font-feature-settings:"dlig" 1;
            // font-feature-settings:"dlig=1" ;
            // -moz-font-feature-settings:"dlig" 1;
            // -ms-font-feature-settings: "dlig" 1;
            
    
    &:before{
        // transition:all 0.3s ease-out;
            // content:attr(data-icon);
            font-family: 'nemo-icons';
            // font-size: 22px;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
            // color:$claro;
            position: relative;
            line-height: 0;
        }


    
    &.arrow-down:before{   
        content:"\AC10";
    }
    &.vise-icon:before{   
        content:"\AC09";
    }
    &.consultation-icon:before{   
        content:"\AC02";
    }
    &.news-icon:before{   
        content:"\AC03";
    }
    &.doc-icon:before{   
        content:"\AC04";
    }
    &.publication-icon:before{   
        content:"\AC01";
    }
    &.upload-icon:before{   
        content:"\AC06";
    }
    &.join-icon:before{   
        content:"\AC12";
    }
    &.email-icon:before{   
        content:"\AC07";
    }
    &.only-icon:before{   
        content:"\AC11";
    }
    &.eye-icon:before{   
        content:"\AC08";
    }
    &.download-icon:before{   
        content:"\AC05";
    }
    
    
    
    
    
    
    


    


}